import React from "react"
import { ImageContainer } from "./index.style"

interface Props {
  children: React.ReactNode
}

const HeaderImageContainer: React.FC<Props> = ({ children }) => (
  <ImageContainer>{children}</ImageContainer>
)

export { HeaderImageContainer }
