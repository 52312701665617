import styled from "styled-components"
import { Style } from "../../style"

const ImageContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: ${Style.layout.maxWidth.main}px;
  margin-bottom: ${Style.megaClamp(1, 2)};
`

export { ImageContainer }
